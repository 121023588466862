body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
  Deskeo overrides
  TODO: bake into material-ui theme API
*/

/* AppBar override */
header.MuiAppBar-root {
  border: none !important;
  background-color: #221E53;
  border-bottom: 1px solid #fff;
  background-image: url("https://www.deskeo.com/wp-content/themes/nq-deskeo/assets/img/pattern_light.png");
  background-repeat: repeat;
  background-size: 100px 174px;
}

/* RaLayout override */
.layout {
  background-color: #F9FAFB;
  background-image: url("https://www.deskeo.com/wp-content/themes/nq-deskeo/assets/img/pattern.png");
  background-repeat: repeat;
  background-size: 100px 174px;
}
